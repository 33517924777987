import React from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./page_align.module.scss";

interface PageAlignProps {
  className?: string;
  innerClassName?: string;
  style?: React.CSSProperties;
}

const PageAlign: React.FC<PageAlignProps> = ({
  className,
  innerClassName,
  style,
  children,
}) => {
  return (
    <div className={combineClasses(styles["page-align"], className)}>
      <div
        className={combineClasses(styles.content, innerClassName)}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

export default PageAlign;
