/**
 * @file Contains animated toggle menu
 */
import React from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./mc_menu.module.scss";

/**
 * Builds hamburger toggle menu that animates to x symbol when open
 * Used in {@link ../layout/components/nav_bar.js|NavBar}
 *
 * @param {Object} props contains component properties
 * @returns {React.Component} animated toggle menu
 */
interface McMenuProps {
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const McMenu: React.FC<McMenuProps> = ({ isOpen, onClick, className }) => {
  return (
    <div
      className={combineClasses(
        styles["menu-toggle"],
        isOpen && styles["open"],
        className
      )}
      onClick={onClick}
    >
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </div>
  );
};
export default McMenu;
