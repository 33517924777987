import React from "react";
import Link from "next/link";

import logoGreen from "./images/logo.svg";
import logoBlack from "./images/logo_black.svg";
import logoWhite from "./images/logo_white.svg";

import styles from "./Logo.module.scss";

export interface LogoProps {
  size?: React.CSSProperties["fontSize"];
  className?: string;
  variant?: "default" | "white" | "black";
}

const Logo: React.FC<LogoProps> = ({
  className,
  size,
  variant = "default",
}) => {
  const logo = {
    default: logoGreen,
    white: logoWhite,
    black: logoBlack,
  }[variant];

  return (
    <Link href="/">
      <a
        className={className}
        style={{
          fontSize: size,
        }}
      >
        <img src={logo.src} alt="Kikoff logo" className={styles.logo} />
      </a>
    </Link>
  );
};

export default Logo;
