import React from "react";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import { combineClasses } from "@kikoff/utils/src/string";

import { AppStoreBadge, PlayStoreBadge } from "@component/badges";
import { newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`nav-login = Login
nav-signup = Sign up
nav-demo = Try the demo`),
  es: new FluentResource(`nav-login = Acceso
nav-signup = Regístrate
nav-demo = Prueba el demo`),
};

interface LandingMenuProps {
  className?: string;
  menuItems: string[][];
}

const LandingMenu: React.FC<LandingMenuProps> = ({ className, menuItems }) => {
  const l10n = newReactLocalization(RESOURCES);
  const router = useRouter();

  return (
    <div
      className={combineClasses(styles["landing-menu"], className)}
      data-cy="links"
    >
      <LocalizationProvider l10n={l10n}>
        {menuItems.map(([label, path]) => (
          <Link href={path} key={label}>
            <a data-cy="link" className={`${styles.item} text:large`}>
              {label}
            </a>
          </Link>
        ))}
        <Link href="/login">
          <a className={`${styles.item} text:large`} data-cy="login">
            <Localized id="nav-login">Login</Localized>
          </a>
        </Link>
        <Link href="/onboarding">
          <a className={styles["inverse-button"]} data-cy="sign-up">
            <Localized id="nav-signup">Sign up</Localized>
          </a>
        </Link>

        <div className={styles["badge-wrapper"]} data-cy="store-icons">
          <AppStoreBadge />
          <PlayStoreBadge />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default LandingMenu;
